import { LinkNavigation } from '@app/components/table-cell-rendering-helper-components/link/models/link-navigation.model';
import { RoleBundle } from '@app/shared/role-bundle.model';
import { NamedList } from '@app/shared/named-list.model';

export class User {
	businessEmail: string;
	businessEmailAsBase64: string = '';
	firstName: string;
	firstNameAsBase64: string = '';
	lastNameAsBase64: string = '';
	lastName: string = '';
	userId: string;
	userName: string;
	userNameAsBase64: string = '';
	floors: string;
	hasContactEMail: boolean;
	hasContactPush: boolean;
	hasContactSMS: boolean;
	hasEmail: boolean;
	optOutEmail: boolean;
	optOutSMS: boolean;
	optOutVoice: boolean;
	primaryLocation: boolean;
	name: string;
	locationEntityId: string = '';
	locationEntityName: string;
	locationEntityNameAsBase64: string = '';
	locations: Array<any>;
	roles: Array<RoleBundle>;
	roleBundleName: string;
	roleBundleNameAsBase64: string = '';
	userStateId: string;
	userStateName: string;
	userStateNameAsBase64: string = '';
	hasContactPhone: boolean;
	hasContactDesktop: boolean;
	profileURL: string;
	profileURLAsBase64: string = '';
	userStringLanguageId: string = '';
	userStringLanguageCode: string;
	userStringLanguageCodeAsBase64: string = '';
	userStringLanguageName: string;
	userStringLanguageNameAsBase64: string = '';
	title: string = '';
	titleAsBase64: string = '';

	navigations?: { [lastName: string]: LinkNavigation };
	groupedLocations?: Array<NamedList>;
	isResendingInvite: boolean = false;
	isSelfRegistered: boolean = false;
	isMessageOnly: boolean = false;
	isBusinessContinuityAuditor: boolean = false;
	isBusinessContinuityManager: boolean = false;
	isBusinessContinuityTeamLead: boolean = false;
	isBusinessContinuityAssignee: boolean = false;

	// FRONT END USE - DropdownModule
	label: string = '';
	value: string = '';

	entities: any;

	constructor() {
	}
}
